.topNLots {
  @apply my-12 flex flex-col gap-10 md:gap-14;

  & .resultCard {
    @apply flex flex-col gap-4 font-body md:gap-6;

    & .image {
      @apply relative xs_max:align-full;

      & img {
        @apply w-full;
      }

      & .ranking {
        @apply absolute bottom-0 left-0 w-10 bg-red-base py-0.5 text-center font-semibold text-white md:w-11 md:py-1 lg:w-14 lg:py-1.5;
      }
    }

    & .meta {
      @apply flex flex-col justify-between gap-2 md:flex-row md:gap-12;

      & .title {
        @apply m-0 font-body;
      }

      & .description {
        @apply mb-0 mt-1.5 font-secondary text-base italic text-gray-500;
      }

      & .saleResult {
        @apply flex items-center gap-4 md:items-start;

        & .badge {
          @apply block p-0 md:-top-0.5 lg:-top-1.5;

          & img {
            @apply m-0 max-w-[35px] md:max-w-[40px] lg:max-w-[50px];
          }
        }

        & .price {
          @apply font-semibold text-red-base;
        }
      }
    }

    & .cta {
      @apply mt-2 w-full self-start font-secondary text-base tracking-normal md:w-[290px];
    }

    & .ranking,
    & .title,
    & .price {
      @apply text-2xl md:text-3xl lg:text-4xl;
    }
  }
}
