.videoEmbed {
  @apply mb-8;

  & .wrapper {
    @apply relative w-full overflow-hidden pt-[56.25%];

    &:has(:global([class*='video-placeholder'])) {
      @apply pt-0;
    }
  }

  & iframe {
    @apply absolute inset-0 h-full w-full;
  }

  & .caption {
    @apply mb-6 mt-2 text-base text-caption;
  }
}
