.card {
  @apply bg-white text-center shadow-md;

  &:hover,
  &:focus-within {
    @apply cursor-pointer;

    & h4 {
      @apply text-red-hover underline;
    }
  }

  & .img {
    @apply hidden sm:block;

    & img {
      @apply aspect-video object-cover;
    }
  }

  & .content {
    @apply p-5 uppercase leading-none;

    & h4 {
      @apply m-0 text-2xl leading-none tracking-wide;

      & a {
        @apply block truncate;
      }
    }

    & h5 {
      @apply m-0 truncate text-lg tracking-wide text-gray-500;
    }

    & a {
      @apply text-red-base no-underline;
    }
  }
}
