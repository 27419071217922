.lotCard {
  @apply relative z-10 mx-auto h-full w-full cursor-pointer;
  @apply bg-white shadow-[0_2px_10px_0_rgba(164,163,163,0.5)];
  @apply flex flex-col;
  @apply lg:hover:z-20 lg:hover:!scale-105;
  @apply @container/card;

  @media (width >= calc(theme('screens.lg'))) {
    &:hover,
    &:focus-within {
      & .estimateRunDate {
        @apply shrink;
      }

      & .socialIcons,
      & .estimate {
        @apply visible relative opacity-100;
      }

      & .runDate {
        @apply hidden;
      }
    }
  }

  & .link {
    @apply absolute z-[1] h-full w-full;
  }

  & .saleResultDetails {
    @apply flex w-full flex-wrap justify-between;

    &:has(.priceBadge) {
      @apply bg-white;

      & .saleResultBadge {
        @apply after:bg-transparent;
      }
    }
  }

  & a:has(.saleResultDetails) {
    @apply no-underline;
  }

  & .saleResultBadge {
    @apply absolute z-[11] before:hidden after:hidden;

    &:global(.sold) {
      @apply -left-2 px-0;
    }

    &:global(.bid-goes-on) {
      @apply pl-3;
      @apply after:-z-[1] after:flex after:w-6 after:[left:unset];
      @apply after:-right-3 after:-skew-x-[25deg];
    }

    & :global(.soldBadge) {
      @apply mt-[-7px] max-w-[50px] object-contain;
    }

    & :global(.bidGoesOnBadge) {
      @apply h-auto max-w-[130px] object-contain;
    }
  }

  & .priceBadge {
    @apply z-[2] justify-end px-3 text-lg;
    @apply after:[width:unset];
  }

  & .saleResultBadge + .priceBadge,
  & .priceBadge:only-child {
    @apply w-full;
  }

  & .image {
    @apply relative;

    &.withAspectRatio {
      & img {
        @apply aspect-video;
      }
    }

    & .overlayIcon {
      @apply pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 fill-white opacity-80;
    }
  }

  & .meta {
    @apply flex;
  }

  & .lotBadges {
    @apply relative m-0 flex w-3/4 min-w-0 bg-gray-800 px-4 py-1.5;
    @apply whitespace-nowrap font-secondary text-sm font-bold uppercase text-white;
    @apply after:absolute after:-right-2 after:top-0 after:z-[2] after:h-full after:w-4 after:-skew-x-[25deg] after:bg-gray-800;

    & + ul li {
      @apply justify-end text-sm;
    }

    & .mainAttraction + li {
      @apply ml-2;
    }

    &.withMainAttraction {
      @apply bg-red-base after:bg-red-base;

      & li:not(.lotNumber, .mainAttraction) {
        @apply hidden;
      }

      & .withSeparator::after,
      & .mainAttraction {
        @apply hidden @[310px]/card:inline;
      }
    }
  }

  & .lotNumber {
    &.withSeparator {
      @apply flex after:mx-1.5 after:self-center after:font-normal after:content-['//'];
    }
  }

  & .content {
    @apply grid h-full grid-rows-[min-content_min-content_1fr] p-4 pt-2;

    & * {
      @apply m-0;
    }
  }

  & .auction,
  & .title,
  & .body {
    @apply font-secondary font-bold;
  }

  & .auction {
    @apply z-[3] w-fit text-lg uppercase text-red-base;
  }

  & .title {
    @apply z-[3] text-2xl;

    & a {
      @apply block;
    }
  }

  & .body {
    @apply font-normal italic text-gray-600;
  }

  & .footer {
    @apply mt-4 flex items-end justify-between self-end;
  }

  & .estimateRunDate {
    @apply text-[0.75rem] font-bold uppercase leading-4 lg:shrink-0;

    & span {
      @apply block text-gray-600;
    }
  }

  & .estimate {
    @apply lg:absolute lg:opacity-0;
  }

  & .socialIcons {
    @apply z-[1] mb-0 flex gap-1;
    @apply lg:invisible lg:opacity-0;

    & li {
      @apply flex;
    }
  }

  &[data-inview='false'] {
    @apply lg:scale-75 lg:opacity-0;
  }

  &[data-inview='true'] {
    @apply delay-100;
  }
}
