.mediaText {
  @apply mb-12 flex flex-col items-center gap-6 md:grid md:grid-cols-12 md:gap-10;

  &.noStack {
    @apply grid grid-cols-12;
  }

  &.reverse {
    @media (width <= calc(theme('screens.md') - 1px)) {
      @apply flex-col-reverse;
    }

    &.mediaLeft {
      @media (width <= calc(theme('screens.md') - 1px)) {
        @apply flex-col;
      }
    }
  }

  & .text,
  & .media {
    @apply col-span-6 row-start-1 row-end-auto flex flex-col items-start justify-center;
  }

  & .text {
    @apply col-start-1 col-end-auto items-start;

    & .title {
      @apply mb-8;
    }

    & .body {
      @apply mb-24;
    }

    & :global(.heading):first-child {
      @apply mt-0;
    }
  }

  & .media {
    @apply relative col-start-2 col-end-auto mb-0 w-full bg-opacity-20;
  }

  &.mediaLeft {
    @apply flex-col-reverse;

    & .media {
      @apply col-start-1 col-end-auto;
    }

    & .text {
      @apply col-start-2 col-end-auto;
    }
  }

  & .tagText {
    @apply mb-1 font-body text-xs font-bold uppercase text-gray-600;
  }

  &.alignTop {
    @apply items-start;
  }

  &.alignBottom {
    @apply items-end;
  }

  &.imageFill {
    & .media {
      @apply h-full min-h-[16rem] max-w-full bg-cover;

      & img {
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        width: 1px;
      }
    }
  }
}
