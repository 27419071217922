.mediaContentSelector {
  @apply my-4 lg:my-8;

  &.gridLayout {
    @apply grid gap-4 md:grid-cols-2 lg:grid-cols-4;
  }

  & > * {
    @apply m-0;
  }
}
