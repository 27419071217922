.megaMenuAuctions {
  @apply mt-4 flex gap-4;

  & .column {
    @apply w-1/4;
  }

  & .card {
    @apply min-h-0;

    &:hover,
    &:active,
    &:focus-within {
      @apply cursor-pointer;

      :global(.heading) {
        @apply text-red-base underline;
      }

      :global(.card__body) {
        @apply underline;
      }
    }

    & img {
      @apply aspect-video;
    }

    :global(.content) {
      @apply mt-3 p-0 2xl:mt-4 !important;

      :global(.heading) {
        @apply font-body text-sm uppercase 2xl:text-base !important;
      }
    }
  }

  .subLinks {
    @apply mt-1 2xl:mt-2 2xl:flex;

    .subLink {
      @apply block font-body text-xs font-normal leading-tight text-gray-600 2xl:mr-2 2xl:text-sm;

      &:hover {
        @apply text-red-base underline;

        &::after {
          @apply text-gray-600;
        }
      }

      &::after {
        @apply 2xl:ml-2 2xl:content-['|'];
      }

      &:last-of-type::after {
        @apply 2xl:content-[''];
      }
    }
  }
}
