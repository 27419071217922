.auctionSelector {
  @apply flex flex-col gap-4;

  & .card {
    @apply relative flex max-h-[520px] min-h-[500px] items-center justify-center print:min-h-[200px] print:break-inside-avoid;

    & .linkOverlay {
      @apply absolute z-[2] h-full w-full;
    }

    & .overlay {
      @apply absolute z-[1] h-full w-full bg-black opacity-60;
    }

    & .content {
      @apply z-[2] flex flex-col items-center p-4 text-center text-white;

      & .title {
        @apply m-0 font-bold uppercase;
        @apply [font-size:clamp(2.25rem,7vw,3.75rem)];
      }

      & .dates,
      & .subtitle {
        @apply m-0 font-secondary text-xl font-bold uppercase;
      }

      & .subtitle {
        @apply text-yellow;
      }

      & .dates {
        @apply mt-3;
      }

      & .links {
        @apply mt-8 grid grid-cols-2 justify-between gap-8 text-white md:flex print:hidden;

        &:has(a:only-child) {
          @apply grid-cols-1;
        }

        & a {
          @apply flex flex-col items-center gap-2;
        }
      }
    }
  }
}
