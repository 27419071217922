.saleResultBadge {
  @apply relative z-[2] m-0 flex items-center p-0 pl-3 md:pl-5;

  &:not(:global(.sold)) {
    @apply h-[28px] bg-white after:absolute after:left-[100%] after:top-0 after:z-[1] after:h-full after:w-[100vw] after:bg-white md:h-[36px];
    @apply before:absolute before:-left-2 before:top-0 before:z-[1] before:h-full before:w-4 before:-skew-x-[25deg] before:[background-color:inherit] md:before:-right-3 md:before:w-6;
  }

  & :global(.soldBadge) {
    @apply mt-[-9px] max-w-[45px] md:max-w-none;
  }

  & :global(.bidGoesOnBadge) {
    @apply max-w-[120px] md:max-w-none;
  }
}
