.lotlist {
  & .print {
    @apply mb-4 flex w-full justify-end print:hidden;

    & button {
      @apply m-0 max-w-fit;
    }
  }

  & table {
    @apply w-full border-collapse;

    & tr {
      @apply border-b border-solid border-gray-100;
    }

    & th {
      @apply uppercase;
    }

    & th,
    & td {
      @apply px-3 py-3 text-left align-top text-sm sm:px-5;

      &.lg {
        @apply sm:w-3/5;
      }
    }

    & tbody tr {
      &:nth-child(odd) {
        @apply bg-gray-50;
      }

      &:hover,
      &:focus-within {
        @apply bg-yellow/5;
      }

      & td {
        @apply border-r border-solid border-gray-100;

        &:last-of-type {
          @apply border-none;
        }
      }
    }

    & a {
      @apply text-red-base underline;

      &:hover,
      &:focus {
        @apply text-red-hover no-underline;
      }
    }
  }

  & .nav {
    @apply mt-8 lg:mt-0;
  }

  & .runDates {
    @apply flex flex-wrap;

    & li {
      @apply whitespace-nowrap p-0 font-secondary text-sm;

      &.active {
        & a {
          @apply text-red-base underline;
        }
      }

      & .sep {
        @apply pl-1 pr-2;
      }
    }
  }
}
