.search {
  @apply grid gap-8 py-8 lg:grid-cols-[25%_1fr] xl:grid-cols-[20%_1fr];

  &_noGrid {
    @apply block;
  }

  & .mainContainer {
    & .header {
      @apply mb-4;

      & .auction {
        @apply mb-8;

        & .title {
          @apply my-0 uppercase tracking-wide;
        }

        & .date {
          @apply font-primary font-medium uppercase tracking-wide text-gray-600;
        }
      }

      & .searchContainer {
        @apply mt-4 flex flex-col items-center gap-4 md:mt-0;
        @apply lg:grid lg:grid-cols-[2fr_1fr] 2xl:grid-cols-[3fr_1fr];

        & .filtersSort {
          @apply flex w-full flex-col justify-between gap-4 xs:flex-row lg:block;
        }

        & .searchBoxRoot {
          @apply w-full;
        }

        & .searchBoxForm {
          @apply relative flex flex-row-reverse overflow-hidden;

          &.filled,
          &:focus-within {
            & label {
              @apply -translate-y-1.5 scale-90 text-sm;
            }
          }

          & label {
            @apply pointer-events-none absolute left-12 top-3 whitespace-nowrap text-sm text-gray-400 md:top-2.5 md:text-base;
            @apply origin-top-left transform transition-transform;
          }
        }

        & .searchBoxInput {
          @apply w-full pl-12 pt-5 text-sm;
          @apply lg:text-base;
          @apply border border-gray-200;
          @apply appearance-none focus:outline-none;
        }

        & .searchBoxSubmitIcon {
          @apply absolute left-2 top-2;

          & svg {
            @apply h-7 w-7;
          }
        }

        & .searchBoxResetIcon {
          @apply absolute right-2 top-2;
        }

        & .searchBoxIcon {
          @apply stroke-gray-650;
        }

        & input[type='search']::-webkit-search-cancel-button {
          @apply appearance-none;
        }
      }
    }

    & .hitsList {
      @apply grid grid-cols-1 items-stretch justify-center gap-x-4 gap-y-6;
      @apply sm:grid-cols-2 xl:grid-cols-3 4xl:grid-cols-4;
    }

    & .bottomNavContainer {
      @apply mt-4 flex flex-col items-center gap-4 sm:mt-8 lg:mt-14;
      @apply lg:grid lg:grid-cols-[2fr_1fr] 2xl:grid-cols-[3fr_1fr];
    }

    & .searchContainer,
    & .bottomNavContainer {
      & .nav {
        @apply self-stretch py-0;
      }
    }

    & .noResultsText {
      @apply hidden;
    }

    & .emptyHitsList + .noResultsText {
      @apply block;
    }

    & .noResults + .nav {
      @apply hidden;
    }

    & .nav {
      @apply flex flex-col items-center justify-between;
      @apply gap-4 pt-4 sm:gap-8 sm:pt-8 lg:pt-14;
      @apply lg:flex-row;

      & :global(.ais-Pagination) {
        @apply min-h-[40px] w-full sm:[width:unset];
      }

      & .paginationList {
        @apply mb-0 flex items-center;
        @apply divide-x-2 divide-gray-200;

        & li {
          @apply first:border-l-2 last:!border-r-2;
        }
      }

      & .paginationItem {
        @apply flex items-center justify-center text-gray-600;
        @apply border-y-2 border-gray-200;
        @apply h-10 w-full sm:h-[46px] sm:w-[46px] sm:text-lg;
        @apply hover:bg-red-base hover:text-white hover:no-underline;
        @apply transition-all duration-100 ease-in-out;

        & .paginationLink {
          @apply flex h-full w-full items-center justify-center;
        }
      }

      & .paginationDisabledItem {
        @apply pointer-events-none text-gray-200;
      }

      & .paginationSelectedItem {
        @apply bg-red-base text-white;
      }

      & .paginationFirst,
      & .paginationPrevious,
      & .paginationNext,
      & .paginationLast {
        @apply cursor-pointer border-gray-200;
      }

      & .paginationFirst,
      & .paginationPrevious {
        & svg {
          @apply rotate-180;
        }
      }

      & .noRefinement {
        @apply invisible;
      }

      @media print {
        @apply hidden;
      }
    }
  }
}
