.megaMenuMedia {
  @apply mt-4 grid grid-cols-2 grid-rows-4 gap-4 2xl:grid-cols-3 2xl:grid-rows-3;

  & .card {
    @apply col-start-1 col-end-2 row-start-1 row-end-4 2xl:col-span-1 2xl:row-end-4;

    & div[class*='Card_image'],
    & div[class*='Card_photo'] {
      @apply !mt-0;
    }

    & :global(.flat):hover,
    & :global(.flat):active,
    & :global(.flat):focus-within {
      @apply scale-100 cursor-pointer;

      & :global(.heading) {
        @apply text-red-base underline;
      }

      & :global(.card__body) {
        @apply underline;
      }

      & :global(.tagText) {
        @apply underline;
      }
    }

    & :global(.content) {
      @apply !px-0;

      & a:hover {
        @apply no-underline;
      }

      & :global(.heading) {
        @apply font-body text-base font-bold !important;
      }

      & :global(.card__body) {
        @apply font-body text-sm font-normal text-gray-600 !important;
      }
    }
  }

  /* Grid layout */
  & .mediaTextItem:global(.mediaTextItem-1) {
    @apply col-span-1 row-span-1 2xl:col-start-2 2xl:col-end-3;
  }

  & .mediaTextItem:global(.mediaTextItem-2) {
    @apply col-span-1 row-span-1 2xl:col-start-3 2xl:col-end-4;
  }

  & .mediaTextItem:global(.mediaTextItem-3) {
    @apply col-span-1 row-start-3 row-end-4 2xl:col-start-2 2xl:col-end-3 2xl:row-span-1;
  }

  & .mediaTextItem:global(.mediaTextItem-4) {
    @apply col-start-1 col-end-2 row-start-4 row-end-5 2xl:col-start-3 2xl:col-end-4 2xl:row-span-1;
  }

  & .mediaTextItem:global(.mediaTextItem-5) {
    @apply col-start-2 col-end-3 row-start-4 row-end-5 2xl:col-start-2 2xl:col-end-3 2xl:row-span-1;
  }

  & .mediaTextItem:global(.mediaTextItem-6) {
    @apply hidden 2xl:col-start-3 2xl:col-end-4 2xl:row-span-1 2xl:block;
  }

  /* Adjustments to Media Text component styles */
  & .mediaTextItem :global(.heading) {
    @apply my-0 font-body text-sm font-bold;
  }

  & :global(.media-text) {
    @apply mb-0;
  }

  & a:hover :global(.heading),
  & a:active :global(.heading) {
    @apply text-red-base underline;
  }
}
