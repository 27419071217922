.richtext {
  &.center {
    @apply text-center;
  }

  &.dropcap {
    &::first-letter {
      @apply float-left mr-4 text-8xl font-bold;
    }
  }

  /* Links */
  & a {
    @apply font-bold underline decoration-red-base hover:text-red-base;
  }

  &[data-inview='false'] {
    @apply lg:opacity-0;
  }

  &[data-inview='true'] {
    @apply delay-300 lg:opacity-100 lg:duration-500;
  }

  /* Inline Images */
  & img {
    @apply mx-1 inline;
  }
}

/* Paragraphs */
p.richtext {
  @apply mb-5 last:mb-0;
}
