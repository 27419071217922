.card {
  @apply relative flex min-h-[200px] w-full flex-col bg-white;
  @apply shadow-[0_2px_8px_0_rgba(0,0,0,0.14)];
  @apply transition-transform duration-300 ease-in-out lg:focus-within:scale-105 lg:hover:scale-105;

  &:has(:focus-visible) {
    @apply rounded-[1px] outline outline-2 outline-[#005fcc];
  }

  &.horizontal {
    @apply grid grid-cols-1 grid-rows-[minmax(200px,_1fr)] lg:grid-cols-2 lg:grid-rows-1;
  }

  & .link {
    @apply no-underline outline-none before:absolute before:inset-0 before:z-[1];
  }

  & .image {
    @apply relative w-full;

    &.photo {
      @apply aspect-[4/3];
    }

    &.video {
      @apply aspect-video;
    }

    &.square {
      @apply aspect-square;
    }

    &.auto {
      @apply aspect-auto;
    }

    & .overlay {
      @apply absolute z-[1] h-full w-full bg-black opacity-40;
    }

    & .overlayIcon {
      @apply pointer-events-none absolute left-1/2 top-1/2 z-[2] -translate-x-1/2 -translate-y-1/2 fill-white opacity-80;
    }
  }

  & .content {
    @apply flex flex-grow flex-col gap-3 px-10 py-4;

    & * {
      @apply m-0;
    }

    & > div:last-child {
      @apply mt-auto;
    }

    & .meta {
      @apply flex border-b border-b-gray-800 pb-2 font-primary text-lg font-bold uppercase tracking-[1px];

      & li {
        @apply text-red-base;
      }

      & .sep {
        @apply mx-2;
      }
    }

    & .title {
      @apply font-secondary text-2xl font-bold;
    }

    & .tagText {
      @apply mb-1 font-body text-xs font-bold uppercase text-gray-600;
    }

    & .body {
      @apply font-secondary;
    }
  }

  &.flat {
    @apply grid-cols-1 grid-rows-[minmax(200px,_1fr)] space-y-2 shadow-none 2xl:grid-cols-[1fr,_1.5fr] 2xl:grid-rows-1;

    & .content {
      @apply gap-0 p-0 lg:px-5;

      & .meta {
        @apply border-none p-0 font-secondary text-base;
      }

      & .title {
        @apply font-secondary text-3xl font-bold;
      }

      & .body {
        @apply mt-2 text-gray-600;
      }
    }
  }
}
