.image {
  &.isRounded {
    & img {
      @apply rounded-full;
    }
  }

  &.alignCenter {
    @apply mx-auto;

    & img {
      @apply mx-auto;
    }
  }

  &.alignLeft,
  &.alignRight {
    @apply my-6 md:m-8 md:mt-0;
  }

  &.alignLeft {
    @apply md:float-left md:ml-0;
  }

  &.alignRight {
    @apply md:float-right md:mr-0;
  }

  &.alignFull {
    @apply align-full;

    & img {
      @apply w-full;
    }
  }

  &.alignWide {
    @apply align-wide;
  }

  & img {
    @apply mb-0 block;
  }

  & .caption {
    @apply mb-6 mt-2 text-base text-caption;
  }

  &.hasImageFill {
    @apply mb-0 h-full;

    & img {
      @apply h-full object-cover;
    }
  }

  &.noSizes {
    @apply aspect-video;
  }

  & .imageFill {
    @apply object-cover;
  }

  /* Sibling elements */

  & + .image {
    @apply mt-6;
  }

  & + p {
    @apply mt-5;
  }
}
