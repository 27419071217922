.noResults {
  @apply mx-auto my-8 max-w-[630px] text-center;

  & .primaryText {
    @apply font-primary text-3xl font-bold uppercase tracking-wide lg:text-5xl;
  }

  & .ctas {
    @apply my-10 flex flex-col items-center justify-center gap-4 lg:flex-row;

    & a,
    & button {
      @apply w-full py-2 font-secondary text-base tracking-normal;
      @apply xs:w-[250px] lg:text-lg;
    }
  }
}
