.table {
  @apply overflow-auto;

  & table {
    @apply w-full border-collapse;

    & tr {
      @apply print:break-inside-avoid;
    }

    & td,
    & th {
      @apply p-3;
    }

    & thead {
      & tr {
        @apply border-b;
      }
    }

    & tfoot {
      & tr {
        @apply border-t;
      }

      & td {
        @apply font-bold;
      }
    }
  }

  & .caption {
    @apply p-3 text-base text-caption;
  }

  &.withBorders {
    & table {
      & th + th,
      & td + td {
        border-left-color: var(--border-color);
        border-left-width: var(--border-width);
      }

      & td {
        border-top-color: var(--border-color);
        border-top-width: var(--border-width);
      }
    }
  }

  &:global(.is-style-stripes) {
    & tr {
      @apply odd:bg-gray-100;
    }
  }

  &.fixedLayout {
    & table {
      @apply table-fixed;
    }
  }
}
