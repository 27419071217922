.pullquote {
  @apply mb-6 mt-6 px-0 font-secondary md:mt-2;

  &.alignFull {
    @apply align-full [max-width:unset];
  }

  &.alignLeft,
  &.alignRight {
    @apply md:w-1/3;
  }

  &.alignLeft {
    @apply ml-0 md:float-left md:mr-8;
  }

  &.alignRight {
    @apply mr-0 md:float-right md:ml-8;
  }

  &.alignWide {
    @apply align-wide;
  }

  &:global(.text-center) {
    & svg {
      @apply mx-auto;
    }
  }

  &:global(.text-right) {
    & svg {
      @apply ml-auto;
    }
  }

  & blockquote {
    @apply border-l-[3px] border-red-base pl-5;
  }

  & svg {
    @apply mb-2 fill-red-base;
  }

  & p {
    @apply text-[1.375rem] font-medium italic leading-[1.45];
  }

  & cite {
    @apply text-lg font-bold text-gray-600;
  }
}
